<template>
  <!-- 储值卡充值 -->
  <div class="box-card">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="130px" class="demo-ruleForm">
      <div class="title" style="margin-top: 0;">基本信息</div>
      <el-divider></el-divider>
      <el-form-item label="车牌号：" style="margin-bottom:0px;"> {{ infoDetail.plate_number }}</el-form-item>
      <el-form-item label="停车场：" style="margin-bottom:0px;"> {{ infoDetail.parking_name }}</el-form-item>
      <el-form-item label="卡类型：" style="margin-bottom:0px;"> {{ getWayName("CarOwnerCardType",
        infoDetail.car_owner_card_type) }}</el-form-item>
      <el-form-item label="当前余额："
        style="margin-bottom:10px;">￥{{ Number(infoDetail.stored_value_amount).toFixed(2) }}</el-form-item>
      <el-form-item label="充值金额：" prop="renew_amount">
        <el-input v-model="addForm.renew_amount" maxlength="8" placeholder="请输入续费金额"
          @input="(e) => (addForm.renew_amount = validNumber(e))"></el-input>
        <span style="margin-left:15px;">元</span>
      </el-form-item>
    </el-form>
    <div class="title">续费记录</div>
    <el-divider></el-divider>
    <el-table class="tablestyle" :data="infoDetail.paymentList" border>
      <el-table-column prop="plate_number" label="车牌号"></el-table-column>
      <el-table-column prop="parking_name" label="停车场"> </el-table-column>
      <!-- <el-table-column label="卡类型">
            <template slot-scope="scope">
              {{ scope.row.card_type   ? getWayName("CarOwnerCardType", scope.row.card_type)   : "" }}
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">{{getWayName("CardStatus", scope.row.card_status)}}</template>
          </el-table-column> -->
      <el-table-column label="充值金额（元）">
        <template slot-scope="scope">{{ scope.row.renew_amount.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="余额（元）">
        <template slot-scope="scope">{{ scope.row.overage_amount.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="操作日期" min-width="100px">
        <template slot-scope="scope">
          <span>{{ $moment(scope.row.update_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator_name" label="操作员"> </el-table-column>
    </el-table>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="send" v-preventReClick>确认充值</lbButton>
  </div>
</template>

<script>
import request from '@/api/carmanager'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      // 充值表单
      addForm: {
        renew_amount: ''
      },
      addFormRules: {
        renew_amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' }
        ]
      },
      // 基本信息
      infoDetail: {}
    }
  },
  computed: {
    ...mapGetters(['getWayName']),
    ...mapState('menuList', ['isRoutW'])
  },
  created () {
    this.fnGetDtoredCardDetailPage()
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取页面数据
    async fnGetDtoredCardDetailPage () {
      const res = await request.rechargeInit({
        card_id: this.id
      })
      if (res.Code === 200) {
        this.infoDetail = res.Data
      }
    },
    // 保存
    send () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          console.log(this.addForm.renew_amount)
          if (parseFloat(this.addForm.renew_amount) > 0) {
            this.requestRecharge()
          } else {
            this.$msg.warning('金额不能为0')
          }
        } else {
          return false
        }
      })
    },
    async requestRecharge () {
      this.addForm.card_id = this.id
      const res = await request.recharge(this.addForm)
      if (res && res.Code === 200) {
        this.$refs.addFormRef.resetFields()
        this.fnGetDtoredCardDetailPage()
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }

}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 320px;
  }
}

.demo-ruleForm {
  /deep/ .el-input {
    width: 200px;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  margin-top: 0 !important;

  .checkButton {
    position: absolute;
    right: 0px;
    bottom: 0;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
    bottom: 0;
  }
}</style>
